// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-repertoire-js": () => import("./../../../src/pages/repertoire.js" /* webpackChunkName: "component---src-pages-repertoire-js" */),
  "component---src-pages-wedding-band-berkshire-js": () => import("./../../../src/pages/wedding-band-berkshire.js" /* webpackChunkName: "component---src-pages-wedding-band-berkshire-js" */),
  "component---src-pages-wedding-band-west-london-js": () => import("./../../../src/pages/wedding-band-west-london.js" /* webpackChunkName: "component---src-pages-wedding-band-west-london-js" */),
  "component---src-pages-wedding-band-windsor-maidenhead-js": () => import("./../../../src/pages/wedding-band-windsor-maidenhead.js" /* webpackChunkName: "component---src-pages-wedding-band-windsor-maidenhead-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

