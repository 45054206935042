export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/products/",
    text: "Bookings",
  },
  {
    path: "/blogs/",
    text: "blogs",
  },
  {
    path: "/repertoire/",
    text: "repertoire",
  },
  {
    path: "https://store.theupbeatsduo.com",
    text: "store",
  },
  {
    path: "/contact/",
    text: "contact",
  },
]
